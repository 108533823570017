.about {
    display: flex;
    flex-direction: column;
    margin: 0px 25% ;
    
    .about-info {
        color: #636e72;
        line-height: 32px;

        a {
            text-decoration: none;
            color: #2ecc71;
        }
    }
}