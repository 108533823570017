.experience {
    display: flex;
    flex-direction: column;
    margin: 0px 25% ;

    .section-header{
        margin-bottom: 16px;
    }

    .content-switcher {
        display: flex;
        flex-direction: column;

        .tab-container {
            display: flex;
            flex-direction: row;
            cursor: pointer;
            color: #2ecc71;
            margin-bottom: 16px;
            font-size: 24px;
            justify-content: space-between;
            span {
                padding: 16px;
            }

            span:hover {
                border-bottom: 1px solid #2ecc71;
            }
            .active {
                background-color: #212529;
                border-bottom: 1px solid #2ecc71;
            }
            
        }

        .tab-container span:hover {
            background-color: #212529;
        }
    }
}

.experience-details {
    display: flex;
    flex-direction: column;
    color: #636e72;

    a {
        text-decoration: none;
        color: #2ecc71;
    }

    .position-header {
        font-size: 32px;
        font-weight: 500;
    }

    .flex-row span{
        margin-right: 8px;
    }

    .experience-details-timeline {
        margin-bottom: 24px;
    }
}