.welcome {
    display: flex;
    flex-direction: column;
    margin: 0px 25% ;
   

    .welcome-title {
        color: #2ecc71;
        font-size: 13px;
        font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", "Lucida Console", Monaco, monospace;
    }

    .welcome-name {
        color: #b2bec3;
        font-size: 100px;
        font-family: Calibre, "San Francisco", "SF Pro Text", -apple-system, system-ui, BlinkMacSystemFont, Roboto, "Helvetica Neue", "Segoe UI", Arial, sans-serif;
    }

    .welcome-subheader {
        color: #636e72;
        font-size: 64px;
        font-family: Calibre, "San Francisco", "SF Pro Text", -apple-system, system-ui, BlinkMacSystemFont, Roboto, "Helvetica Neue", "Segoe UI", Arial, sans-serif;
    }
}
