.my-navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 50%;
    margin-left: 50%;
    text-decoration: none;
    position: fixed;

    .nav-item {
        font-size: 13px;
        font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", "Lucida Console", Monaco, monospace;
        padding: 12px;
        color: #2ecc71;
    }
}

@keyframes fadeInAnimation {
    0% {
        top: -100px;
        opacity: 0.01;
    }
    100% {
        top: 0px;
        opacity: 1;
    }
}
